import React from 'react';
import './Links.css'

const Links = () => {
    return (
        <>
            <div className='links-container'>
                <div className='link-container'>
                    <a href='https://t.me/ton_runnerbot'>Play on Telegram</a>
                    <img src='arrow1.png'/>
                </div>
                <div className='link-container'>
                    <a href='https://t.me/ton_runner'>Join TON Runner community</a>
                    <img src='arrow1.png'/>
                </div>
                <div className='link-container'>
                    <a href='https://crolux.online'> Crolux team</a>
                    <img src='arrow1.png'/>
                </div>
            </div>
        </>
    );
};

export default Links;
