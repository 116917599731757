import './Main.css'

function Main() {
    return (
        <div className="Main">
            <div className='opening'>
                <h1>TON Runner</h1>
                <h1>Our  <span><img id='tg-icon' src='telegram.png' /></span><span className='telegram-gradient'> Telegram</span> mini app game is <br />LIVE NOW</h1>
                <form action="https://t.me/ton_runnerbot">
                    <button className='button-4' type="submit">play on Telegram</button>
                </form>
                <h2>RUN TO WIN 600 <img id='ton-icon' src='ton_symbol.png' /></h2>
            </div>
            <div className='mock-container'>
                <div className='mock'><img  src='mock1.png'/></div>
                <div className='second-mock mock'><img src='mock8.png'/></div>
                <div className='mock'><img  src='mock7.png'/></div>
            </div>
            <main>
                <div className="about-container">
                    <div className="about-block">
                        <div>
                            <h3>Compete with your friends</h3>
                            <p>Invite your friends to join the competition and unlock exclusive bonuses! Challenge each other to see who can achieve the highest scores or complete the most levels. With our exciting multiplayer features, you can engage in friendly rivalry and teamwork, making the gaming experience even more thrilling. Don't miss out on the fun – invite your friends today and embark on an epic adventure together!</p>
                        </div>
                        <img src='cok-video1.gif'></img>
                    </div>
                    <div className="about-block odd">
                        <div>
                            <h3>Unlock characters</h3>
                            <p>Discover a diverse roster of characters, each with their own unique abilities and traits waiting to be unlocked! Whether it's speed, strength, agility, or special powers, every character brings something special to the table. From fearless warriors to cunning strategists, explore a world where every character has a role to play and a story to tell. Collect them all and assemble your dream team to conquer challenges and embark on thrilling adventures!</p>
                        </div>
                        <img src='gran-video.gif'></img>
                    </div>
                    <div className="about-block">
                        <div>
                            <h3>Grow to make progress</h3>
                            <p>Embark on a journey of growth and progress as you start your adventure as a Novice and strive to become a seasoned Marathoner! Progress through various stages of development, honing your skills, overcoming challenges, and achieving milestones along the way. With dedication, perseverance, and determination, you'll gradually evolve into a formidable force to be reckoned with. Embrace the journey, embrace the challenges, and watch yourself grow into the ultimate champion!</p>
                        </div>
                        <img src='robot-video1.gif'></img>
                    </div>
                    <div className="about-block odd">
                        <div>
                            <h3>Be the farest runner</h3>
                            <p>Strive to become the furthest runner and claim your victory with an incredible reward of 600 trCoins! Lace up your running shoes and embark on an exhilarating race against time and obstacles. Push your limits, overcome challenges, and sprint towards glory as you compete to reach the farthest distance possible. With each step you take, you edge closer to the grand prize and earn your rightful place as the ultimate champion of the track!</p>
                        </div>
                        <img src='aj-video.gif'></img>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default Main;
