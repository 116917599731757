import React from 'react';
import './Rules.css'

const Rules = () => {
    return (
        <>
            <div className='rules-container'>
                <h1>Rules</h1>
                <div className='rule-container'>
                    <div>
                        <h3>Start the TON Runner bot</h3>
                        <p>Press `/start` to initiate the bot and begin your exciting journey! Invite your friends to join the fun and share the link to this website to expand the adventure. Don't forget to send invitations to earn additional trCoins! Let the games begin!</p>
                    </div>
                    <img src='mock1.png' />
                </div>
                <div className='rule-container'>
                    <div>
                        <h3>Main menu</h3>
                        <p>Welcome to the main menu of the game! Here, you can access various features such as checking the leaderboard, visiting the shop, and starting the game to earn some trCoins.</p>
                    </div>
                    <img src='mock2.png' />
                </div>
                <div className='rule-container'>
                    <div>
                        <h3>Game process</h3>
                        <p>At the bottom of the screen, you'll find the game controller. Use the 🅱️ button for tap control, the 🎥 button for changing camera settings, and buttons on the left for activating your boosters.</p>
                    </div>
                    <img src='mock6.png'/>
                </div>
                <div className='rule-container'>
                    <div>
                        <h3>Check the leaderboard</h3>
                        <p>To check the current leaders, simply press on the 'Statistics' option in the main menu. See who's the farthest runner at the moment and strive to reach the Marathoner league!</p>
                    </div>
                    <img src='mock5.png'/>
                </div>
                <div className='rule-container'>
                    <div>
                        <h3>Shop</h3>
                        <p>In the shop section, you can purchase new characters, acquire boosts, and claim daily boxes. Connect your wallet to explore the possibilities of each character and enhance your gaming experience.</p>
                    </div>
                    <img src='mock12.png'/>
                </div>
                <div className='rule-container'>
                    <div>
                        <h3>Explore new characters</h3>
                        <p>Discover new characters with unique abilities and traits! Explore the vast selection available in the game and find the perfect companion for your adventures.</p>
                    </div>
                    <img src='mock9.png'/>
                </div>
                <div className='rule-container'>
                    <div>
                        <h3>Invite friends</h3>
                        <p>Want to share the excitement with your friends? Invite them to join the adventure! Gather your squad and race towards greatness together. Remember, the more, the merrier! Let's see who can conquer the Marathoner league first!</p>
                    </div>
                    <img src='mock11.png'/>
                </div>
            </div>
        </>
    );
};

export default Rules;
