import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Rules from './Components/Rules.js'
import Main from './Components/Main.js'
import Header from './Components/Header.js'
import Conditions from './Components/Conditions.js';
import Links from './Components/Links.js';

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
         <Route path="/rules" element={ <Rules /> } />
         <Route path="/how-to-win" element={ <Conditions/> } />
         <Route path="/links" element={ <Links/> } />
         <Route path="/" element={ <Main /> } />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
