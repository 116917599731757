import React from 'react';

const Conditions = () => {
    return (
        <>
            <>
            <div className='rules-container'>
                <h1>Page is under construction</h1>
                <img src='barrier.png' />
            </div>
        </>

        </>
    );
};

export default Conditions;
