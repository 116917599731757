import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css'

function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <header>
            <div className="brand">
                <span><img src='trCoin.png' alt='Brand Logo'/></span>
                <div><Link to="/">TON Runner</Link></div>
            </div>
            <button className="burger" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
            </button>
            <nav className={isMenuOpen ? "nav-active" : ""}>
                <Link onClick={() => setIsMenuOpen(!isMenuOpen)} to="/">About</Link>
                <Link onClick={() => setIsMenuOpen(!isMenuOpen)} to="/rules">Rules</Link>
                <Link onClick={() => setIsMenuOpen(!isMenuOpen)} to="/how-to-win">How to Win</Link>
                <Link onClick={() => setIsMenuOpen(!isMenuOpen)} to="/links">Links</Link>
            </nav>
        </header>
    );
}

export default Header;
